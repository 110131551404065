import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "use-nod"
    }}>{`Use NOD`}</h1>
    <h2 {...{
      "id": "agreements"
    }}>{`Agreements`}</h2>
    <p>{`PTOs must make an agreement with Entur on the use of NOD, on behalf of their system suppliers. The PTO will then have access to the necessary passwords to use NOD.
A third party who, for example, wants to sell products in combination with collective tickets will have to make an agreement with a PTO and call services at this PTO. Only PTOs call NOD directly.`}</p>
    <h2 {...{
      "id": "technical"
    }}>{`Technical`}</h2>
    <p>{`The system vendors must have access to NOD to place orders, and get a password for their NOD Clients.`}</p>
    <h2 {...{
      "id": "customize-your-own-sales-solution"
    }}>{`Customize your own sales solution`}</h2>
    <p>{`A PTO must integrate its sales solution with NOD Backoffice.`}</p>
    <h2 {...{
      "id": "develop-nod-client"
    }}>{`Develop NOD client`}</h2>
    <p>{`A PTO must develop its own NOD Clients alone, or in cooperation with other PTOs. In this development work, one can greatly benefit from the NOD Client API developed for this purpose.`}</p>
    <h2 {...{
      "id": "establishing-transaction-flow"
    }}>{`Establishing transaction flow`}</h2>
    <p>{`The PTO must decide how the results of NOD Pickups are to be communicated (whether transaction flow is necessary or not). It must then be agreed with Entur how these transactions should be retrieved.`}</p>
    <h2 {...{
      "id": "develop-your-own-plugins"
    }}>{`Develop your own plugins`}</h2>
    <p>{`Most products should be covered by existing Plugins. For special needs, separate plugins can be developed by the PTO itself. These can then be linked to NOD by agreement with Entur.`}</p>
    <h2 {...{
      "id": "reusable-libraries"
    }}>{`Reusable libraries`}</h2>
    <p>{`A reference JAVA API is being developed for NOD clients (Android, Javascript (Native) and stationary). This can be used to get started quickly with NOD Client development.`}</p>
    <h2 {...{
      "id": "tickets-check"
    }}>{`Tickets Check`}</h2>
    <p>{`To check a ticket, the control unit must have a copy of the keys needed to verify the contents of the ticket medium. This is today's key on mobile tickets and the read key to travel cards. Contact Entur to access this.
NOD can check a ticket using the Inspection plugin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      